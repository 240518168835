@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    @apply bg-[#f5f5f7];
}

@layer components {
    .text-small {
        font-size: .7em;
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.social-icons .icon {
    transition: transform 0.3s ease-in-out;
}

.social-icons .icon:hover {
    transform: scale(1.2);
}

@keyframes bounce-like {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-15px);
    }
    60% {
        transform: translateY(-5px);
    }
}

@keyframes bounce-dislike {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-5px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.animate-like {
    animation: bounce-like 0.6s;
}

.animate-dislike {
    animation: bounce-dislike 0.6s;
}